/** @jsx jsx */

import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import ContinueReading from '../../components/continue-reading'
import DynamicImage from '../../components/dynamic-image'
import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import SignUpCallToAction from '../../components/sign-up-call-to-action'
import Table from '../../components/table'
import UniversalLink from '../../components/universal-link'
import { VideoProps } from '../../components/video'
import mdxStyles from '../../constants/mdx-styles'
import routes from '../../constants/routes'

const PageBreak: FC = () => <div className="pagebreak" />

const Video: FC<Pick<VideoProps, 'caption' | 'poster' | 'src'>> = ({ poster, src, caption: children }) => {
  return (
    <figure>
      <video {...{ poster, src }} controls />
      <figcaption {...{ children }} />
    </figure>
  )
}

interface BlogPostTemplateProps {
  data: {
    contentfulBlogPost: {
      heroImage?: any
      publishDate: any
      author: {
        image: IGatsbyImageData
        name: string
        title: string
        company: string
      }
      description: {
        childMarkdownRemark: {
          html: string
        }
      }
      body: {
        childMarkdownRemark: {
          html: string
        }
        childMdx: {
          body: string
        }
      }
      logo: {
        title: string
        file: {
          url: string
        }
      }
      slug: string
      tags: string[]
      title: string
    }
    allContentfulEntry: {
      edges: {
        node: {
          title: string
          gatsbyPath: string
        }
      }
    }
  }
}

const BlogPostTemplate: FC<BlogPostTemplateProps> = ({ data }) => {
  const blogPost = data.contentfulBlogPost

  const otherPosts = data.allContentfulEntry?.edges
    .filter(({ node: { title } }) => title && title !== blogPost.title)
    .sort((a, b) => {
      const aDate = new Date(a.node.date)
      const bDate = new Date(b.node.date)

      return bDate.getTime() - aDate.getTime()
    })
    .slice(0, 3)

  const { author, body, description, heroImage: image, title, publishDate, tags, slug } = blogPost
  const bodyMdx = body.childMdx.body
  const descriptionHtml = description.childMarkdownRemark.html
  const haloDescription = descriptionHtml.replace(`<p>`, ``).replace(`</p>`, ``)
  const authorImage = getImage(author?.image)
  const heroImage = getImage(image)
  const haloImage = heroImage?.images?.fallback?.src
    ? `https:${heroImage?.images?.fallback?.src?.split(`?`)[0]}?w=1200&h=628&q=50&fm=png`
    : undefined

  return (
    <Layout>
      <Halo {...{ title }} description={haloDescription} image={haloImage} root="../../" route={`/blog/${slug}`} />
      <Intro
        {...{ title }}
        description={descriptionHtml}
        lead={tags.map((tag, index) => (
          <UniversalLink
            key={tag}
            to={`${routes.contentCenter}/tags/${tag.toLocaleLowerCase().replace(/\ /g, `-`)}`}
            sx={{
              ml: index ? 3 : 0,
              textDecoration: `none`,

              [`:hover, :focus`]: {
                textDecoration: `underline`,
              },
            }}
          >
            {tag}
          </UniversalLink>
        ))}
      />

      <section sx={{ bg: `white_slate8` }}>
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4, py: 5 }}>
          <div sx={mdxStyles}>
            {author && (
              <div
                sx={{
                  color: `inherit`,
                  textDecoration: `none`,
                }}
              >
                <div sx={{ display: `flex`, alignItems: `center`, gap: 3 }}>
                  <div
                    sx={{
                      borderRadius: 999,
                      overflow: `hidden`,
                      display: `inline-block`,
                      backgroundImage: `var(--gradient)`,

                      [`&& img`]: {
                        my: 0,
                      },
                    }}
                  >
                    <GatsbyImage image={authorImage} alt={author.name} />
                  </div>

                  <div>
                    <div sx={{ lineHeight: 1.25, fontWeight: 550 }}>{author.name}</div>
                    <div sx={{ fontSize: 2, lineHeight: 1.25, fontStyle: `italic` }}>{author.title}</div>
                    <div sx={{ fontSize: 2, lineHeight: 1.25 }}>{author.company}</div>
                  </div>
                </div>
              </div>
            )}

            {publishDate && (
              <div
                sx={{
                  mt: 4,
                  fontSize: 2,
                  color: `gray6_slate3`,
                }}
              >
                {publishDate}
              </div>
            )}

            {heroImage && (
              <div sx={{ overflow: `auto`, borderRadius: 6, mt: 4, [`&& img`]: { mt: 0 } }}>
                <GatsbyImage image={heroImage} alt="" />
              </div>
            )}

            <MDXProvider components={{ DynamicImage, PageBreak, Table, UniversalLink, Video }}>
              <MDXRenderer>{bodyMdx}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </section>

      <ContinueReading containerStyles={{ mt: 3, mb: 4 }} posts={otherPosts} />
      <SignUpCallToAction />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      author {
        company

        image {
          gatsbyImageData(height: 64, width: 64)
        }

        name
        slug
        title
      }

      body {
        childMdx {
          body
        }
      }

      description {
        childMarkdownRemark {
          html
        }
      }

      heroImage {
        gatsbyImageData(width: 936)
      }

      publishDate(formatString: "MMMM DD, YYYY")
      slug
      tags
      title
    }

    allContentfulEntry {
      edges {
        node {
          ... on ContentfulBlogPost {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/blog/{ContentfulBlogPost.slug}")
            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }

          ... on ContentfulCaseStudy {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/case-studies/{ContentfulCaseStudy.slug}")
            publishDate
            tags
            title
          }

          ... on ContentfulExternalPost {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            link
            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }
        }
      }
    }
  }
`
